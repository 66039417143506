import { loader } from "../../../assets";
import "./style.scss";

function PageLoading() {
  return (
    <div className="page-loading">
      <img src={loader} alt="Loading" />
    </div>
  );
}

export default PageLoading;
