import React from "react";
import "./style.scss";
import Avatar from "react-avatar";

function UserInfo(props) {
  return (
    <div className="userinfo-wrapper">
      <Avatar name={props.name} size="40px" round="5px" color="#4D4D4D" />
      <div className="userinfo">
        <p>{props.name}</p>
        <span>Anggota</span>
      </div>
    </div>
  );
}

export default UserInfo;
