import React, { Suspense, lazy } from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageLoading } from "../../components/atoms";
import ProtectedRoute from "./ProtectedRoute";
import LoginRoute from "./LoginRoute";

// regsiter page
const Login = lazy(() => import("../../pages/Login"));
const Dashboard = lazy(() => import("../../pages/Dashboard"));
// const Nib = lazy(() => import("../../pages/Nib"));
const JenisPerizinan = lazy(() =>
  import("../../pages/Perizinan/Jenis-perizinan")
);
const DetailPerizinan = lazy(() =>
  import("../../pages/Perizinan/Detail-perizinan")
);
const PertumbuhanPerizinan = lazy(() =>
  import("../../pages/Perizinan/Pertumbuhan-perizinan")
);
const SebaranPerizinan = lazy(() =>
  import("../../pages/Perizinan/Sebaran-perizinan")
);
const SebaranKewenanganIzin = lazy(() =>
  import("../../pages/Perizinan/Sebaran-kewenangan-izin")
);
const DetailNib = lazy(() => import("../../pages/Nib/Detail-nib"));
const PenanamanModal = lazy(() => import("../../pages/Nib/Penanaman-modal"));
const EksporImpor = lazy(() => import("../../pages/Nib/Ekspor-impor"));
const Avg = lazy(() => import("../../pages/Time-proses/Avg"));
const Max = lazy(() => import("../../pages/Time-proses/Max"));
const Min = lazy(() => import("../../pages/Time-proses/Min"));
const ExmImp = lazy (() => import("../../pages/Penanaman-Modal/Ekspor-Impor"));
const FlagUmk = lazy (() => import("../../pages/Penanaman-Modal/Flag-Umk"));
const JenisPemodalan = lazy (() => import("../../pages/Penanaman-Modal/Jenis-Pemodalan"));
const JenisPerusahaan = lazy (() => import("../../pages/Penanaman-Modal/Jenis-Perusahaan"));

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route
            path="/login"
            element={
              <LoginRoute>
                <Login />
              </LoginRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nib/detail-nib"
            element={
              <ProtectedRoute>
                <DetailNib />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nib/penanaman-modal"
            element={
              <ProtectedRoute>
                <PenanamanModal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nib/ekspor-impor"
            element={
              <ProtectedRoute>
                <EksporImpor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/perizinan/jenis-perizinan"
            element={
              <ProtectedRoute>
                <JenisPerizinan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/perizinan/detail-perizinan"
            element={
              <ProtectedRoute>
                <DetailPerizinan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/perizinan/pertumbuhan-perizinan"
            element={
              <ProtectedRoute>
                <PertumbuhanPerizinan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/perizinan/sebaran-perizinan"
            element={
              <ProtectedRoute>
                <SebaranPerizinan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/perizinan/sebaran-kewenangan-izin"
            element={
              <ProtectedRoute>
                <SebaranKewenanganIzin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/time-proses/avg-time-proses"
            element={
              <ProtectedRoute>
                <Avg />
              </ProtectedRoute>
            }
          />
          <Route
            path="/time-proses/max-time-proses"
            element={
              <ProtectedRoute>
                <Max />
              </ProtectedRoute>
            }
          />
          <Route
            path="/time-proses/min-time-proses"
            element={
              <ProtectedRoute>
                <Min />
              </ProtectedRoute>
            }
          />
          <Route
            path="/penanaman-modal/ekspor-impor"
            element={
              <ProtectedRoute>
                <ExmImp />
              </ProtectedRoute>
            }
          />
          <Route
            path="/penanaman-modal/flag-umk"
            element={
              <ProtectedRoute>
                <FlagUmk />
              </ProtectedRoute>
            }
          />
          <Route
            path="/penanaman-modal/status-pemodalan"
            element={
              <ProtectedRoute>
                <JenisPemodalan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/penanaman-modal/jenis-perusahaan"
            element={
              <ProtectedRoute>
                <JenisPerusahaan />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
