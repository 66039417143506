import React from "react";

function Alert({ show, close, message }) {
  return (
    <div
      class={`alert alert-warning alert-dismissible fade ${show ? "show" : ""}`}
      role="alert"
    >
      {message}
      <button
        onClick={() => close(false)}
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  );
}

export default Alert;
