import axios from "axios";
import secureLocalStorage from "react-secure-storage";
// import { useNavigate } from "react-router-dom";

// const navigate = useNavigate();

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use((config) => {
  const token = secureLocalStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      const url = window.location.origin;
      secureLocalStorage.removeItem("token");
      return (window.location.href = `${url}/login`);
    }

    return error.response.data;
  }
);

export default axios;
